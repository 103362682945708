exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-account-management-enterprise-dashboard-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/account-management/enterprise-dashboard.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-account-management-enterprise-dashboard-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-account-management-sso-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/account-management/sso.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-account-management-sso-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-annotations-new-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/annotations/new.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-annotations-new-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-annotations-overview-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/annotations/overview.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-annotations-overview-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-integrations-canstrat-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/integrations/canstrat.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-integrations-canstrat-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-integrations-ssd-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/integrations/ssd.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-integrations-ssd-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-integrations-valnav-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/integrations/valnav.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-integrations-valnav-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-addtolist-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/addtolist.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-addtolist-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-createlist-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/createlist.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-createlist-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-exportdata-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/exportdata.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-exportdata-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-globalfilter-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/globalfilter.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-globalfilter-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-importbylicense-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/importbylicense.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-importbylicense-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-importbyuwi-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/importbyuwi.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-importbyuwi-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-polygonselect-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/polygonselect.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-polygonselect-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-radiusselect-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/radiusselect.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-radiusselect-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-rectangularselect-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/rectangularselect.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-rectangularselect-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-selectionfilter-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/lists/selectionfilter.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-lists-selectionfilter-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-compliance-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/sample-workflows/compliance.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-compliance-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-connectedassets-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/sample-workflows/connectedassets.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-connectedassets-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-land-positions-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/sample-workflows/land-positions.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-land-positions-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-ninja-url-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/sample-workflows/ninja-url.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-ninja-url-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-well-reactivation-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/sample-workflows/well-reactivation.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-sample-workflows-well-reactivation-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tables-overview-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/tables/overview.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tables-overview-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-accountmgmt-enterprisedashboard-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/accountmgmt/enterprisedashboard.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-accountmgmt-enterprisedashboard-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-accountmgmt-sso-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/accountmgmt/SSO.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-accountmgmt-sso-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-basicappsetup-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/gettingstarted/basicappsetup.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-basicappsetup-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-generaltour-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/gettingstarted/generaltour.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-generaltour-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-measurementunits-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/gettingstarted/measurementunits.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-measurementunits-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-multiscreen-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/gettingstarted/multiscreen.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-multiscreen-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-whatsnew-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/gettingstarted/whatsnew.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-gettingstarted-whatsnew-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-integrations-qgis-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/integrations/QGIS.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-integrations-qgis-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-integrations-ssd-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/integrations/SSD.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-integrations-ssd-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-integrations-valnav-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/integrations/valnav.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-integrations-valnav-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-misc-help-annotations-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/misc_help/annotations.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-misc-help-annotations-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-misc-help-projects-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/misc_help/projects.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-misc-help-projects-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-misc-help-tables-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/misc_help/tables.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-misc-help-tables-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-misc-help-upload-gis-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/misc_help/uploadGIS.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-misc-help-upload-gis-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-appendtolist-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/appendtolist.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-appendtolist-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-createlist-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/createlist.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-createlist-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-exportdata-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/exportdata.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-exportdata-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-globalfilter-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/globalfilter.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-globalfilter-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-importby-uwi-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/importbyUWI.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-importby-uwi-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-importbylicense-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/importbylicense.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-importbylicense-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-polygonselect-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/polygonselect.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-polygonselect-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-radiusselect-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/radiusselect.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-radiusselect-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-rectangularselect-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/rectangularselect.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-rectangularselect-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-selectionfiltering-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/selection_and_exports/selectionfiltering.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-selection-and-exports-selectionfiltering-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-tips-and-tricks-custommaps-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/tips_and_tricks/custommaps.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-tips-and-tricks-custommaps-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-tips-and-tricks-googlemaps-satellite-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/tips_and_tricks/googlemaps_satellite.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-tips-and-tricks-googlemaps-satellite-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-tips-and-tricks-sharing-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/threepoint0/tips_and_tricks/sharing.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-threepoint-0-tips-and-tricks-sharing-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-annotating-maps-and-powerpoint-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/tips-and-tricks/annotating_maps_and_powerpoint.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-annotating-maps-and-powerpoint-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-dualscreen-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/tips-and-tricks/dualscreen.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-dualscreen-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-getting-setup-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/tips-and-tricks/getting-setup.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-getting-setup-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-satellite-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/tips-and-tricks/satellite.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-satellite-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-sharing-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/tips-and-tricks/sharing.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-tips-and-tricks-sharing-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-filtering-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/filtering.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-filtering-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-general-tour-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/general-tour.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-general-tour-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-lists-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/lists.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-lists-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-mobile-app-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/mobile-app.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-mobile-app-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-petroninja-2-point-0-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/petroninja2point0.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-petroninja-2-point-0-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-petroninja-thre-epoint-0-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/petroninjaTHREEpoint0.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-petroninja-thre-epoint-0-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-projects-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/projects.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-projects-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-qgis-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/qgis.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-qgis-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-rectangularselect-2-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/rectangularselect2.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-rectangularselect-2-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-units-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/units.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-units-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-uploadinggis-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/build/repo/src/docs/usage/uploadinggis.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-usage-uploadinggis-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js-content-file-path-src-home-index-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js?__contentFilePath=/opt/build/repo/src/home/index.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js-content-file-path-src-home-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

